import React, { useMemo } from 'react';
import HeaderNavigationLink
from './header-navigation-link/HeaderNavigationLink';
import './_header-navigation.scss';
import { getConfig } from '@edx/frontend-platform';
import HeaderNavigationMenu
from './header-navigation-menu/HeaderNavigationMenu';
import { useUserInfo } from '../hooks/useUserInfo';

const getGroupMap = (config) => ({
  mentor: {
    label: 'ЛK ментора',
    href: config.MENTOR_PROFILE_URL,
    isExternal: true,
  },
  staff: {
    label: 'ЛK сотрудника',
    href: config.STAFF_PROFILE_URL,
    isExternal: true,
  },
});

const isContentedHost = () => {
  return window.location.href.includes('contented')
};

const getReferralProgramButton = (config) => ({
  label: 'Пригласить друга',
  href: isContentedHost() ? config.REFERRAL_CONTENTED_URL : config.REFERRAL_SKILLFACTORY_URL,
  isExternal: true,
});

const HeaderNavigation = ({ additionalLinks }) => {
  const userInfo = useUserInfo();
  const groupsMap = getGroupMap(getConfig());

  const availableButtons = userInfo.isAdmin || userInfo.isSuperUser ? Object.values(groupsMap) : userInfo.groups
    .filter(value => groupsMap[value])
    .map(value => groupsMap[value]);
  const navigationLinks = useMemo(() => {
    const baseLinks = [
      {
        label: 'Моё обучение',
        href: getConfig().DASHBOARD_PAGE_PATHNAME,
      },
      ...availableButtons,
      // {
      //     label: 'Дедлайны',
      //     counter: deadlines?.length,
      //     href: "/deadlines",
      //     onClick: () => {
      //         setSidebarVisible(true);
      //     }
      // },
      // {
      //     label: 'Центр карьеры',
      //     href: '/3'
      // },
      // {
      //   label: 'Фабрика проектов',
      //   href: getConfig().PROJECTS_FACTORY,
      //   isExternal: true,
      // },
      ...additionalLinks,
    ];

    if (getConfig().IS_REFERRAL_PROGRAM_ENABLED === 'true') {
      return [getReferralProgramButton(getConfig()), ...baseLinks];
    }

    return baseLinks;
  }, [userInfo, additionalLinks]);

  const [visibleLinks, hiddenLinks] = useMemo(() => {
    const visibleLinks = [];
    const hiddenLinks = [];
    navigationLinks.forEach((link) => {
      if (link.isVisible === false) {
        hiddenLinks.push(link);
        return;
      }
      visibleLinks.push(link);
    });
    return [visibleLinks, hiddenLinks];
  }, [navigationLinks]);

  return (
    <nav className="sf-header-navigation">
      <ul className="sf-header-navigation__list">
        {visibleLinks.map((navLink, index) => (
          <li key={navLink.href}>
            <HeaderNavigationLink {...navLink} key={index} isExternal={navLink.isExternal} />
          </li>
        ))}
        {hiddenLinks.length > 0 && <HeaderNavigationMenu links={hiddenLinks} />}
      </ul>
    </nav>
  );
};

export default HeaderNavigation;
