import React, { useMemo } from 'react';
import classNames from 'classnames';
import './_counter.scss';
import { Text } from '@skillfactory/frontend-theme';

const Counter = ({
  number,
  variant,
}) => {
  const isALot = useMemo(() => number > 99, [number]);
  return (
    <Text
      level={5}
      className={classNames('sf-counter',
        {
          'sf-counter--large': variant === 'large',
          'sf-counter--wide': isALot,
        })}
    >
      {isALot ? '99+' : number}
    </Text>
  );
};

export default Counter;
