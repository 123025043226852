import React from 'react';
import './_header-navigation-link.scss';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import Counter from '../../counter/Counter';

const HeaderNavigationLink = ({
  label,
  href,
  counter,
  onClick,
  isExternal = false,
}) => {
  const handleClick = (e) => {
    e.preventDefault();
    onClick();
  };

  const content = (
    <>
      <span className="sf-header-navigation-link__label">{label}</span>
      {counter !== undefined && counter !== 0 && <Counter number={counter} />}
    </>
  );
  return isExternal
    ? (
      <a className="sf-header-navigation-link" href={href} target="_blank">
        {content}
      </a>
    )
    : (
      <NavLink
        className="sf-header-navigation-link"
        to={href}
        onClick={onClick
          ? handleClick
          : undefined}
      >
        {content}
      </NavLink>
    );
};

export default HeaderNavigationLink;
