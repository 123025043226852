import React, { useState } from 'react';
import './_header-navigation-menu.scss';
import { Dropdown, Text } from '@skillfactory/frontend-theme';
import { ReactComponent as ArrowIcon } from '@skillfactory/frontend-theme/dist/assets/images/icons/arrow.svg';
import classNames from 'classnames';

const HeaderNavigationMenu = ({ links }) => {
  const [isMenuVisible, setMenuVisible] = useState(false);
  return (
    <div className="sf-header-navigation-menu">
      <Dropdown
        className="sf-header-navigation-menu__modal"
        visible={isMenuVisible}
        onClose={() => setMenuVisible(false)}
        content={(
          <ul className="sf-header-navigation-menu__content">
            {links.map((link, i) => (
              <li key={i}>
                <Text level={3} height="short" weight="regular" className="sf-header-navigation-menu__modal-link" as={<a href={link.href} />}>
                  {link.label}
                </Text>
              </li>
            ))}
          </ul>
        )}
      >
        <button onClick={() => setMenuVisible(prev => !prev)} className="sf-header-navigation-menu__button">
          <div>Ещё</div>
          <div className="sf-header-navigation-menu__button-icon-wrapper">
            <ArrowIcon className={classNames('sf-header-navigation-menu__button-icon', { 'sf-header-navigation-menu__button-icon--opened': isMenuVisible })} viewBox="0 0 16 16" width={12} height={12} />
          </div>
        </button>
      </Dropdown>
    </div>
  );
};

export default HeaderNavigationMenu;
