import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from '@edx/frontend-platform/i18n';
import { sendTrackEvent } from '@edx/frontend-platform/analytics';
import { ensureConfig } from '@edx/frontend-platform/config';
import { AppContext } from '@edx/frontend-platform/react';
import { ReactComponent as DzenIcon } from '@skillfactory/frontend-theme/dist/assets/images/social/dzen.svg';
import { ReactComponent as HabrIcon } from '@skillfactory/frontend-theme/dist/assets/images/social/habr.svg';
import { ReactComponent as TelegramIcon } from '@skillfactory/frontend-theme/dist/assets/images/social/telegram.svg';
import { ReactComponent as SkillFactoryIcon } from '@skillfactory/frontend-theme/dist/assets/images/social/skillfactory.svg';
import { ReactComponent as TwitterIcon } from '@skillfactory/frontend-theme/dist/assets/images/social/twitter.svg';
import { ReactComponent as VkIcon } from '@skillfactory/frontend-theme/dist/assets/images/social/vk.svg';
import { getConfig } from '@edx/frontend-platform';
import classNames from 'classnames';
import { useTheme } from '@skillfactory/frontend-theme';
import context from 'react-redux/lib/components/Context';

ensureConfig([
  'PRIVACY_POLICY_URL',
  'USER_AGREEMENT_URL',
  'SUPPORT_URL_CD',
  'SUPPORT_URL_SF',
  'DZEN_URL',
  'HABR_URL',
  'TELEGRAM_URL',
  'SF_URL',
  'TWITTER_URL',
  'VK_URL',
  'MAX_URL',
  'TUNE_IT_URL',
], 'Footer component');

const EVENT_NAMES = {
  FOOTER_LINK: 'edx.bi.footer.link',
};

class SiteFooter extends React.Component {
  constructor(props) {
    super(props);
    this.externalLinkClickHandler = this.externalLinkClickHandler.bind(this);
  }

  externalLinkClickHandler(event) {
    const label = event.currentTarget.getAttribute('href');
    const eventName = EVENT_NAMES.FOOTER_LINK;
    const properties = {
      category: 'outbound_link',
      label,
    };
    sendTrackEvent(eventName, properties);
  }

  render() {
    const {
      PRIVACY_POLICY_URL,
      USER_AGREEMENT_URL,
      SUPPORT_URL_CD,
      SUPPORT_URL_SF,
      DZEN_URL,
      HABR_URL,
      TELEGRAM_URL,
      SF_URL,
      TWITTER_URL,
      VK_URL,
      MAX_URL,
      TUNE_IT_URL,
    } = getConfig();

    return (
      <footer
        role="contentinfo"
        className={classNames('sf-footer', this.props.className)}
      >
        <div className="sf-footer__section">
          <div className="sf-footer__links">
            <a className="sf-footer__link" href={PRIVACY_POLICY_URL}>Политика конфиденциальности</a>
            <a className="sf-footer__link" href={USER_AGREEMENT_URL}>Пользовательское соглашение</a>
            <a className="sf-footer__link" href={this.props.scheme === 'skillfactory' ? SUPPORT_URL_SF : SUPPORT_URL_CD}>Обратиться в поддержку</a>
          </div>
          <div className="sf-footer__socials">
            <a
              className="sf-footer__link sf-footer__link--icon"
              href={DZEN_URL}
            >
              <DzenIcon />
            </a>
            <a
              className="sf-footer__link sf-footer__link--icon"
              href={HABR_URL}
            >
              <HabrIcon />
            </a>
            <a
              className="sf-footer__link sf-footer__link--icon"
              href={TELEGRAM_URL}
            >
              <TelegramIcon />
            </a>
            <a
              className="sf-footer__link sf-footer__link--icon"
              href={SF_URL}
            >
              <SkillFactoryIcon />
            </a>
            <a
              className="sf-footer__link sf-footer__link--icon"
              href={TWITTER_URL}
            >
              <TwitterIcon />
            </a>
            <a
              className="sf-footer__link sf-footer__link--icon"
              href={VK_URL}
            >
              <VkIcon />
            </a>
          </div>
        </div>
        <div className="sf-footer__section sf-footer__section--copyright-with-credits">
          <div className="sf-footer__copyright">
            Skillfactory — oнлайн-школа IT-профессий.
            Онлайн-курсы по Data Science, аналитике, программированию и менеджменту © 2024
          </div>
          <div className="sf-footer__credits">
            <span className="sf-footer__credit">
              Дизайн <a className="sf-footer__link sf-footer__link--credit" href={MAX_URL}>MAX</a>
            </span>
            <span className="sf-footer__credit">
              Разработка <a className="sf-footer__link sf-footer__link--credit" href={TUNE_IT_URL}>TUNE-IT</a>
            </span>
          </div>
        </div>
      </footer>
    );
  }
}

SiteFooter.contextType = AppContext;

SiteFooter.propTypes = {
  intl: intlShape.isRequired,
  logo: PropTypes.string,
  onLanguageSelected: PropTypes.func,
  supportedLanguages: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  })),
  className: PropTypes.string,
  scheme: 'contented' | 'skillfactory',
};

SiteFooter.defaultProps = {
  logo: undefined,
  onLanguageSelected: undefined,
  supportedLanguages: [],
  className: undefined,
  scheme: 'skillfactory',
};

export default injectIntl(SiteFooter);
export { EVENT_NAMES };
