import React from 'react';
import './_user-menu-link.scss';
import classNames from "classnames";
import {Link} from "react-router-dom";

const UserMenuLink = ({
    label,
    icon,
    href,
    isSecondary,
    isExternal = false,
                      }) => {
    const body = <>
        {icon}
        {label}
    </>
    const className = classNames("sf-user-menu-link", {"sf-user-menu-link--secondary": isSecondary})
    return isExternal? <a className={className} href={href}>
        {body}
    </a> :  (
        <Link className={className}
           to={href}>
            {body}
        </Link>
    );
};

export default UserMenuLink;
