import React, { useState } from 'react';
import './_skillfactory-header.scss';
import { ReactComponent as SkillfactoryLogo } from '@skillfactory/frontend-theme/dist/assets/images/logos/skillfactory-logo.svg';
import { ReactComponent as SkillfactoryLogoLight } from '@skillfactory/frontend-theme/dist/assets/images/logos/skillfactory-logo-light.svg';
import { ReactComponent as ContentedLogo } from '@skillfactory/frontend-theme/dist/assets/images/logos/contented-logo.svg';
import { ReactComponent as ContentedLogoDark } from '@skillfactory/frontend-theme/dist/assets/images/logos/contented-logo-dark.svg';
import classNames from 'classnames';
import { ensureConfig, getConfig } from '@edx/frontend-platform';
import { Link } from 'react-router-dom';
import { useScheme, useTheme } from '@skillfactory/frontend-theme';
import UserMenu from './user-menu/UserMenu';
import HeaderNavigation
from './header-navigation/HeaderNavigation';

ensureConfig(['MENTOR_PROFILE_URL', 'STAFF_PROFILE_URL']);

export const headerVariants = {
  WHITE: 'white',
  LIGHT: 'light',
  DARK: 'dark',
};

const themeAndSchemeAndVariantToLogo = (theme, scheme, logo, variant) => {
  const headerLogo = logo ?? scheme;

  if (theme === 'light') {
    if (headerLogo === 'skillfactory') {
      if (variant === headerVariants.DARK) {
        return <SkillfactoryLogoLight />;
      }
      return <SkillfactoryLogo />;
    }
    if (variant === headerVariants.DARK) {
      return <ContentedLogo />;
    }
    return <ContentedLogoDark />;
  }

  if (headerLogo === 'skillfactory') {
    if (variant === headerVariants.LIGHT) {
      return <SkillfactoryLogo />;
    }
    return <SkillfactoryLogoLight />;
  }

  if (variant === headerVariants.LIGHT) {
    return <ContentedLogoDark />;
  }

  return <ContentedLogo />;
};

const SkillFactoryHeader = ({
  variant = headerVariants.WHITE,
  className,
  deadlines = [],
  isDeadlineListLoading = false,
  additionalLinks = [],
  theme = 'light',
  scheme = 'skillfactory',
  logo = 'skillfactory',
  isContented = false,
}) => {
  const [isSidebarVisible, setSidebarVisible] = useState(false);
  return (
    <header className={classNames('sf-header', { [`sf-header--${variant}`]: variant !== headerVariants.WHITE }, className)}>
      <Link to={getConfig().DASHBOARD_PAGE_PATHNAME}>
        {themeAndSchemeAndVariantToLogo(theme, scheme, logo, variant)}
      </Link>
      <div className="sf-header__navigation-and-user-menu">
        <HeaderNavigation additionalLinks={additionalLinks} />
        <UserMenu isContented={isContented} />
      </div>
      {/* <DeadlinesSidebar */}
      {/*    isVisible={isSidebarVisible} */}
      {/*    onHide={() => setSidebarVisible(false)} */}
      {/*    deadlines={deadlines} */}
      {/*    isDeadlineListLoading={isDeadlineListLoading} */}
      {/* /> */}
    </header>
  );
};

export default SkillFactoryHeader;
