import React, { useContext, useMemo, useState } from 'react';
import './_user-menu.scss';
import { ReactComponent as ProfileIcon } from '@skillfactory/frontend-theme/dist/assets/images/icons/profile.svg';
import { ReactComponent as SlackIcon } from '@skillfactory/frontend-theme/dist/assets/images/social/slack.svg';
import { ReactComponent as LogOutIcon } from '@skillfactory/frontend-theme/dist/assets/images/icons/logout.svg';
import { AppContext } from '@edx/frontend-platform/react';
import { getConfig } from '@edx/frontend-platform';
import { Dropdown, Text } from '@skillfactory/frontend-theme';
import UserMenuLink from './user-menu-link/UserMenuLink';

const UserMenu = ({ isContented }) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const { authenticatedUser } = useContext(AppContext);

  const onButtonClick = () => setPopupVisible(prevState => !prevState);
  const navLinks = useMemo(() => [
    {
      icon: <ProfileIcon />,
      label: 'Мой профиль',
      href: isContented ? `${getConfig().STUDENT_PROFILE_CONTENTED_URL}/profile` : `${getConfig().STUDENT_PROFILE_URL}/profile`,
      isExternal: true,
    },
    // { icon: <SlackIcon />, label: 'Рабочее пространство Slack', href: '#2', isExternal: true, },
    {
      icon: <LogOutIcon />, label: 'Выйти', href: getConfig().LOGOUT_URL, isSecondary: true, isExternal: true,
    },
  ], []);

  const buttonLabel = useMemo(() => {
    if (!authenticatedUser?.name) { return ''; }
    const tokens = authenticatedUser.name.split(' ');
    if (tokens.length < 1) { return ''; }
    if (tokens.length === 1) { return tokens[0][0].toUpperCase(); }
    return (tokens[0][0] + tokens[1][0]).toUpperCase();
  }, [authenticatedUser.name]);

  return (
    <div className="sf-user-menu">
      <Dropdown
        onClose={() => setPopupVisible(false)}
        className="sf-user-menu__dropdown"
        visible={isPopupVisible}
        content={(
          <div className="sf-user-menu__content">
            <div className="sf-user-menu__content-header">
              <span className="sf-user-menu__username">{authenticatedUser.name}</span>
              <Text level={5} height="short" weight="regular" className="sf-user-menu__email">{authenticatedUser.email}</Text>
            </div>
            <ul className="sf-user-menu__content-navigation">
              {navLinks.map((navLink) => (
                <li key={navLink.href}>
                  <UserMenuLink {...navLink} />
                </li>
              ))}
            </ul>
          </div>
      )}
      >
        <button className="sf-user-menu__button" onClick={onButtonClick}>
          {buttonLabel}
        </button>
      </Dropdown>
    </div>
  );
};

export default UserMenu;
